import VerticalMenu_VerticalMenuTextWithSeparatorsSkinComponent from '../components/VerticalMenu/viewer/skinComps/VerticalMenuTextWithSeparatorsSkin/VerticalMenuTextWithSeparatorsSkin.skin';
import VerticalMenu_VerticalMenuTextWithSeparatorsSkinController from '../components/VerticalMenu/viewer/VerticalMenu.controller';


const VerticalMenu_VerticalMenuTextWithSeparatorsSkin = {
  component: VerticalMenu_VerticalMenuTextWithSeparatorsSkinComponent,
  controller: VerticalMenu_VerticalMenuTextWithSeparatorsSkinController
};


export const components = {
  ['VerticalMenu_VerticalMenuTextWithSeparatorsSkin']: VerticalMenu_VerticalMenuTextWithSeparatorsSkin
};

